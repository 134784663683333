<template>
  <div class="v-stack h-stretch gap-3" v-if="drive">
    <a class="heading-title-1">{{ drive.name }}</a>
    <div class="card light mn">
      <div class="content">
        <div class="v-stack gap-3">
          <a>Info</a>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Product Name:</label>
            <div class="text-left">{{ drive.productName || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Type:</label>
            <div class="text-left">
              {{ drive.external ? "External" : "Internal" }}
            </div>
          </div>

          <div class="pane-horizontal gap-3">
            <label class="text-right">Storage Type:</label>
            <div class="text-left">
              {{ drive.type ? drive.type : "Not Set" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Form Factor</label>
            <div class="text-left">
              {{ drive.formFactor ? drive.formFactor : "Not Set" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Size:</label>
            <div class="text-left">
              {{ freeSpace.toFixed(2) }} TB free of
              {{ drive.size.toFixed(2) }} TB
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Purchase Date:</label>
            <div class="text-left">
              {{
                  drive.dateOfPurchase
                    ? formatDate(drive.dateOfPurchase, "DD.MM.YYYY")
                    : "Not Set"
              }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Store:</label>
            <div class="text-left">
              {{ drive.storeDescription || "Not Set" }}
            </div>
          </div>
          <a>Speed</a>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Read:</label>
            <div class="text-left">
              {{ drive.speedRead + " MB/s" || "Not Set" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Write:</label>
            <div class="text-left">
              {{ drive.speedWrite + " MB/s" || "Not Set" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">RPM:</label>
            <div class="text-left">{{ drive.speedRPM || "Not Set" }}</div>
          </div>
          <a>Other</a>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Description:</label>
            <div class="text-left">{{ drive.description || "Not Set" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button @click="$router.push('/storage/edit/' + drive._id)" class="edit">
        Edit
      </button>
    </div>
    <a class="heading-title-1">Projects</a>
    <Table v-if="drive.folders" css="1fr 1fr 100px 100px"
      v-on:rowSelected="$router.push('/projects/detail/' + $event.project._id)" :items="
        drive.folders.map((folder) => {
          let temp = folder;
      
          if (folder.project != null) {
            temp.projectName =
              temp.project.client.name +
              ' / ' +
              temp.project.title +
              ' / ' +
              temp.project.subtitle;
      
            temp.url =
              drive.name + '/' + temp.project.title + '/' + temp.project.subtitle;
          } else {
            temp.projectName = 'NULL'
            temp.url = 'NULL'
          }
      
          return temp;
        })
      " :defaultColumn="0" :columns="[
  {
    name: 'Project',
    sort: 'alphabet',
    path: ['projectName'],
  },
  {
    name: 'URL',
    sort: 'alphabet',
    path: ['url'],
  },
  {
    name: 'Type',
    sort: 'alphabet',
    path: ['type'],
  },
  {
    name: 'Size',
    sort: 'numeric',
    path: ['project', 'size'],
    format: ' GB',
    formatMap: (size) => size,
  },
]"></Table>
    <label>Total Projects Size: {{ usedSpace.toFixed(2) }} TB</label>
  </div>
</template>

<script>
const moment = require("moment");
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";

export default {
  props: ["id"],
  components: {
    Table,
  },
  data() {
    return {
      drive: null,
    };
  },
  computed: {
    freeSpace() {
      let freeSpace = 0;
      if (this.drive && this.drive.size) {
        freeSpace = this.drive.size - this.usedSpace;
      }
      return freeSpace;
    },
    usedSpace() {
      let usedSpace = 0;
      if (this.drive && this.drive.folders) {
        for (const folder of this.drive.folders) {
          if (folder.project != null) {
            usedSpace += folder.project.size / 1024;
          }
        }
      }
      return usedSpace;
    },
  },
  methods: {
    ...mapActions(["getDrive"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
  mounted() {
    this.getDrive(this.id)
      .then((drive) => {
        this.drive = drive;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>